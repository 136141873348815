<template>
    <div>
        <section v-if="isOverview" id="card-section">
            <div
                v-if="getShowProfileInOverview && getCategoryImplications_1(category) > 0 || getCategoryImplications_2(category) > 0">
                <ProgressBar :segments_total=category.maxAnswers.length :segments_1=getCategoryImplications_1(category)
                    :segments_2=getCategoryImplications_2(category)>
                </ProgressBar>
            </div>
            <div v-else>
                <ProgressBar :segments_total=category.maxAnswers.length :segments_1=0 :segments_2=0 />
            </div>

            <b-card class='elsi-card clickable' @click="clickedCard(category)" style="z-index: 1; border: none">

                <div class="card-section">

                    <b-card-text style="border-right: 4px dashed #ECF0F3; padding-right: 16px">
                        <div class="headline"> {{ title }}</div>
                        <div> {{ description }} </div>

                    </b-card-text>
                    <div style="display: flex;">
                        <img class="img-fluid" style="padding: 0px 50px 50px 50px" :src="imagePath(id)" width="300" alt="">
                        <div class="starredIcon clickable" style="z-index: 2" @mouseover="mouseOverIcon"
                            @mouseleave="mouseLeaveIcon">
                            <b-icon class="iconStarred" font-scale="4" :icon="starred ? 'star-fill' : 'star'"
                                :class="starred ? 'star-dunkel' : 'star-hell'" @click="toggleIsStarred"></b-icon>
                        </div>
                    </div>
                </div>
            </b-card>
        </section>
        <section id="card-section-overview">
            <div v-if="isProjectOverview">
                <div
                    v-if="getShowProfileInOverview && getCategoryImplications_1(category) > 0 || getCategoryImplications_2(category) > 0">
                    <ProgressBar :segments_total=category.maxAnswers.length :segments_1=getCategoryImplications_1(category)
                        :segments_2=getCategoryImplications_2(category)>
                    </ProgressBar>
                </div>
                <div v-else>
                    <ProgressBar :segments_total=category.maxAnswers.length :segments_1=0 :segments_2=0 />
                </div>
                <b-card class='elsi-card-overview' @click="clickedCard(category)"
                    style="z-index: 1; border: none; margin-bottom: 25px">

                    <div class="card-section-overview">

                        <b-card-text>
                            <div style="display: flex">
                                <div class="headline-projectoverview"> {{ title }}</div>
                                <div class="starredIcon clickable" style="z-index: 2; margin-right: 0px"
                                    @mouseover="mouseOverIcon" @mouseleave="mouseLeaveIcon">
                                    <b-icon class="iconStarred" font-scale="3" :icon="starred ? 'star-fill' : 'star'"
                                        :class="starred ? 'star-dunkel' : 'star-hell'" @click="toggleIsStarred"></b-icon>
                                </div>
                            </div>
                        </b-card-text>

                        <div style="display: flex; justify-content: center;">
                           <img class="img-fluid" :src="imagePath(id)" style="width:300px; max-height:250px;" alt="">
                        </div>
                    </div>
                </b-card>
            </div>
        </section>
    </div>
</template>

<script>

import ProgressBar from "../../../components/ProgressBar.vue"
export default {
    name: 'elsi-card',
    props: {
        id: Number,
        title: String,
        description: String,
        isStarred: Boolean,
        picture: String,
        category: Object,
        isOverview: Boolean,
        isProjectOverview: Boolean
    },
    components: {
        ProgressBar
    },
    computed: {
        starred() {
            return this.category.isStarred
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },

    },
    methods: {
        mouseOverIcon() {
            this.overIcon = true
        },
        mouseLeaveIcon() {
            this.overIcon = false
        },
        clickedCard(category) {
            if (!this.overIcon)
                this.$emit('card-clicked', category)
        },
        imagePath(id) {
            return require(`@/assets/images/elsi-cards/${id}k.svg`);
        },
        test() {
        },
        toggleIsStarred() {
            const payload = { categoryId: this.category.id, value: !this.category.isStarred }
            this.$store.dispatch('survey/toggleIsStarredCategory', payload)
        },
        getCategoryImplicationsLength(category) {
            return this.$store.getters['survey/getCategoryImplicationsLength'](category)
        },
        getCategoryImplications_1(category) {
            return this.$store.getters['survey/getCategoryImplications_1'](category)
        },
        getCategoryImplications_2(category) {
            return this.$store.getters['survey/getCategoryImplications_2'](category)
        },
    },
    beforeMount() {
        let implicationsOne = []
        let implicationsTwo = []
        this.category.implications.forEach((implication) => {
            if (implication.implication.points === 1) {
                implicationsOne.push(implication)
            }
            if (implication.implication.points === 2) {
                implicationsTwo.push(implication)
            }
        }
        )
        this.segment1 = implicationsTwo.length
        this.segment2 = implicationsOne.length

    },

    data() {
        return {
            wertesystem: "Finden Sie alleine oder im Team heraus, welche Werte  Ihre Forschung bestimmen und Ihrem Forschungsprojekt eine Form geben sollen.",
            profil: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes  bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
            karten: "Informieren Sie sich zu relevan-ten ELSI-Kategorien und lassen Sie sich von den Fallbeispielen und dem Reflexionsprozess inspirieren.",
            reflexion: "Reflektieren Sie ihr eigenes Forschungprojekt anhand einer indivialisierbaren ELSI-Checkliste und leisten Sie den Transfer von den ELSI-Inhalten zum eigenen Forschungsvorhaben.",
            activeColor: '#F2AF4A',
            maxLength: this.category.maxAnswers.length,
            segment1: 0,
            segment2: 0,
            overIcon: false,
        }
    }
}

</script>

<style lang="scss" scoped>
.star-hell {
    color: white;
    background-color: $star-hell;
}

.star-dunkel {
    color: white;
    background-color: $star-dunkel;
}

.starredIcon {
    position: absolute;
    top: 16px;
    right: 16px;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}

.elsi-card {
    padding: 12px, 0px, 0px, 0px;
    height: 25rem;
    overflow: hidden;
    border: none;

    &:hover {
        background-color: lightgrey;
    }
}

.elsi-card-overview {
    overflow-y: auto;
    height: auto;
}

.headline {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 20px;
    /* identical to box height, or 114% */
    color: $elsi-color-schrift-normal;
}

.headline-projectoverview {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 20px;
    /* identical to box height, or 114% */
    color: $elsi-color-schrift-normal;

}

section#card-section {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    // padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .card-section {
        display: grid;
        grid-template-columns: 55% 45%;
        grid-column-gap: 2rem;
    }

}

section#card-section-overview {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    // padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }


}
</style>
