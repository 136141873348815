<template>
    <div>
        <div v-show="showValueReflection && isOverview">
            <div id="drop-zone-overview" class="drop-zone-overview" ref="dropZone">
                <div class="editPen">
                    <feather-icon class="clickable" font-scale="1.5" icon="Edit3Icon" @click="clickEdit"></feather-icon>
                </div>
                <div class="item" v-for="item in getAllValuesListTwo" :key="item.id">
                    <div class="drag-el-overview"
                        :style="{ position: 'absolute', top: calculateTop(item), left: calculateLeft(item) }">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!isOverview">
            <div class="row grow w-100">
                <div class="col-1">
                    <NavSidebar></NavSidebar>
                </div>
                <div class="main col-11 h-100 py-3 padding-subpages">
                    <NavHeader buttonLabel="Zur Projektübersicht" tutorialName="valueReflection"
                        :icons="['question-circle', 'download']" :link="true" linkTo="/uebersicht"></NavHeader>
                    <HeaderCard info='true' :icons="[]">
                        <template #title>
                            ELSI-Wertereflexion
                        </template>
                        <template #description>
                            <p>Mit der ELSI-Wertereflexion können Sie sich ein Bild zu <b>zentralen Werten</b> machen, die
                                für Ihr angestrebtes Forschungsergebnis (z. B. Technologie) von Bedeutung sind. Dabei ist es
                                wichtig, jeden Wert aus unterschiedlichen Kontexten zu betrachten, d. h. sich in
                                verschiedene Personengruppen und deren Interessen hineinzuversetzen. Mögliche Werte- und
                                Interessenskonflikte sollen dadurch frühzeitig erkannt werden, um ihnen rechtzeitig mit
                                entsprechenden Maßnahmen begegnen zu können.</p>
                        </template>
                        <template #info>
                            <p>Ziehen Sie die Werte in die <b>Zielscheibe</b>, die für Ihr angestrebtes Forschungsergebnis
                                am grundlegendsten sind. Ordnen Sie diese nach Wichtigkeit von innen nach außen. Lesen Sie
                                sich auch die <b>Kontextbeschreibungen</b> der Begriffe durch und überlegen Sie, welche
                                Perspektiven in Ihrem Projekt berücksichtigt werden müssen.
                                Die Kontextbeschreibungen sind mögliche Aussagen und keine ethisch-normativen Vorgaben.</p>
                        </template>
                    </HeaderCard>

                    <section id="value-section">
                        <div class="value-section">
                            <div class="get-zone" @drop="onDrop($event, 1)" @dragover.prevent>

                                <div v-for="item in getAllValuesListOne" :class="item.isActive ? 'isActive' : 'isInactive'"
                                    :key="item.id" style="margin-top: 6px; margin-bottom: 48px;"
                                    @click="itemClick($event, item)" draggable @dragstart="startDrag($event, item)"
                                    @mouseover="mouseOver($event, item)" @dragover="noDropHere($event)">
                                    {{ item.title }}
                                </div>

                            </div>
                            <div class="middle-section">
                                <div class="drop-zone-overlay">
                                    <div id="drop-zone-profile" ref="dropZoneProfile" class="drop-zone"
                                        @drop="onDrop($event, 2)" @dragover.prevent>

                                        <div v-for="item in getAllValuesListTwo" :key="item.id" class="item"
                                            :class="item.isActive ? 'isActive' : 'isInactive'"
                                            @click="itemClick($event, item)" @mouseover="mouseOver($event, item)"
                                            :style="{ position: 'absolute', top: calculateTop(item), left: calculateLeft(item) }"
                                            draggable @dragstart="startDrag($event, item)" @dragover="noDropHere($event)">
                                            {{ item.title }}
                                        </div>

                                    </div>
                                </div>

                                <div
                                    style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                    <div v-show="!showOwnValueInput">
                                        <b-button class="addButton" v-b-tooltip.hover
                                            title='Eigenen Wert eingeben, dann zum Hinzufügen außerhalb des Eingabefelds klicken.'
                                            @click="changeButton">Hinzufügen...</b-button>
                                    </div>
                                    <div v-show="showOwnValueInput">
                                        <input v-model="ownValue" type="text" ref="inputOwnValue" @blur="focusOut"
                                            class="form-control ownValue" id="value" autofocus placeholder="Eigener Wert">
                                    </div>
                                    <div id="drop-zone-delete" class="drop-zone-delete" @drop="deleteItem($event)"
                                        @dragover.prevent>
                                        <feather-icon icon="Trash2Icon" />
                                    </div>
                                </div>

                            </div>
                            <div class="get-and-delete">
                                <div class="get-zone" style="align-items: end" @drop="onDrop($event, 3)" @dragover.prevent>

                                    <div v-for="item in getAllValuesListThree"
                                        :class="item.isActive ? 'isActive' : 'isInactive'" :key="item.id"
                                        style="margin-top: 6px; margin-bottom: 48px;" @click="itemClick($event, item)"
                                        draggable @dragstart="startDrag($event, item)" @mouseover="mouseOver($event, item)"
                                        @dragover="noDropHere($event)">
                                        {{ item.title }}
                                    </div>

                                </div>
                            </div>

                            <div id="context-panel">
                                <b-card no body style="width: 100%; padding:16px;">
                                    <b-tabs class="tabs" active-nav-item-class="active-tab" justified v-model="tabIndex">
                                        <b-tab ref="tabKontextbeschreibung" title="Kontextbeschreibung" active
                                            :title-link-class="'tab-title-class'" :disabled="disabled">
                                            <b-card-text class="tab-text" ref="tabKontext">
                                                <h4 class="pt-4">{{ currentItem.title }}</h4>
                                                <p class="pb-3 headline">{{ currentItem.headline }}</p>
                                                <div v-for="itemText, index in currentItem.text" :key="index">
                                                    <p class="sub-title">{{ itemText.sub_title }}</p>
                                                    <p>{{ itemText.sub_text }}</p>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab title="Eigene Notizen" :title-link-class="'tab-title-class'">
                                            <b-card-text class="tab-text" ref="tabNote">
                                                <b-form-textarea v-model="getNote" size="sm"
                                                    placeholder="Ihre Notizen zu dem gewählten Wert."
                                                    style="height: 100%; width: 100%" />
                                            </b-card-text>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                                <div style="display: flex; align-self: flex-end;">
                                    <Button btnStyle="btn-primary" label="In Projektübersicht übernehmen" :chevron="true"
                                        :chevronLeft="false" :link=true linkTo="/uebersicht"></Button>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>

            <b-modal v-if="isOverview === false" v-model="isShowTutorialStart" title='Hinweise' ok-only>
                <Tutorial tutorialName="valueReflection" />
                <template #modal-footer>
                    <p>Das Resultat dieser Übung hat keinen Einfluss auf die weiteren Ergebnisse im ELSI-Profil oder der
                        Projektreflexion.</p>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import NavSidebar from "../../../components/Nav_Sidebar.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import OverviewCollapse from "../../../components/Overview_Collapse.vue"
import Button from "../../../components/Button.vue"
import FeatherIcon from '../../../components/FeatherIcon.vue'
import Tutorial from '../../Tutorial.vue'

export default {
    name: 'value-reflection',
    components: {
        HeaderCard,
        OverviewCollapse,
        NavHeader,
        NavSidebar,
        Button,
        FeatherIcon,
        Tutorial
    },
    props: {
        isOverview: {
            type: Boolean,
            default: false
        },
        isVisible: Boolean,
        isPDF: Boolean
    },
    computed: {

        disabledKontext() {
            return this.disabled
        },
        showValueReflection() {
            return this.$store.getters['survey/getShowValueReflection']
        },
        getActiveValue() {
            return this.$store.getters['survey/getActiveValue']
        },
        getValueReflection() {
            return this.$store.getters['survey/getAllValues']
        },
        getAllValuesListTwo() {
            return this.$store.getters['survey/getAllValuesListTwo']
        },
        getAllValuesListThree() {
            return this.$store.getters['survey/getAllValuesListThree']
        },
        getAllValuesListOne() {
            return this.$store.getters['survey/getAllValuesListOne']
        },
        getAutonomieValue() {
            return this.$store.getters['survey/getAutonomieValue']
        },
        showTutorialAtStart() {
            return this.$store.getters['survey/showTutorialValueReflection']
        },
        getNote: {
            get() {
                return this.currentItem.note
            },
            set(value) {
                let payload = {
                    id: this.currentItem.id,
                    title: this.currentItem.title,
                    note: value,
                    isActive: false,
                }
                this.$store.commit('survey/UPDATE_VALUE_NOTE', payload)
            }
        }

    },
    methods: {
        deleteItem(evt) {
            let isOwnValue = evt.dataTransfer.getData('isOwn')

            if (isOwnValue === 'true') {

                let itemID = evt.dataTransfer.getData('itemID')
                this.$store.commit('survey/DELETE_VALUE', itemID)
            } else {
                this.$vs.notify({
                    time: 6000,
                    title: 'Kein eigener Wert!',
                    text: 'Nur selbst hinzugefügte Werte können gelöscht werden',
                    color: 'danger'
                })
            }
            this.currentItem = {}

        },
        calculateTop(item) {
            let newTop
            if (this.isOverview)
                newTop = Math.round(this.heightDropZoneOverview / 100 * item.percentTop)
            else
                newTop = Math.round(this.heightDropZone / 100 * item.percentTop)

            return newTop + 'px'
        },
        calculateLeft(item) {

            let newLeft
            if (this.isOverview)
                newLeft = Math.round(this.widthDropZoneOverview / 100 * item.percentLeft)
            else
                newLeft = Math.round(this.widthDropZone / 100 * item.percentLeft)

            return newLeft + 'px'
        },
        clickEdit() {
            this.$router.push('/valueReflection')
        },
        changeButton() {
            if (this.showOwnValueInput) {
                this.showOwnValueInput = false
            } else {
                this.showOwnValueInput = true
                this.$refs.inputOwnValue.focus()
            }

            this.$nextTick(() => {
                this.$refs.inputOwnValue.focus();
            })
        },

        addValue() {
            if (this.ownValue === '') {
                this.$vs.notify({
                    time: 6000,
                    title: 'Wert fehlt!',
                    text: 'Leere Werte können nicht hinzugefügt werden.',
                    color: 'danger'
                })
            } else {
                const itemID = this.$store.getters['survey/getValueCount'] + 1
                let payload = {
                    id: itemID,
                    title: this.ownValue,
                    list: 3,
                    x: '',
                    y: '',
                    note: '',
                    isActive: false,
                    isOwn: true
                }
                this.$store.commit('survey/ADD_VALUE', payload)
            }
            this.changeButton()
        },
        getValueReflectionList(list) {

            return this.$store.getters['survey/getAllValues'].filter((item) => item.list === list)

        },
        getValueReflectionItemIsActive(item) {
            return this.$store.getters['survey/getValueReflectionItemIsActive'](item)
        },
        noDropHere(evt) {
            evt.stopPropagation()
        },
        startDrag(evt, item) {
            let rect = evt.target.getBoundingClientRect();
            // mouseX/mouseY is offset of mouse pointer from left/top corner of dragged object (in px)
            this.mouseX = evt.clientX - rect.left
            this.mouseY = evt.clientY - rect.top

            if (JSON.stringify(this.currentItem) === '{}') {
                this.$store.commit('survey/SET_ACTIVE_VALUE', item)
                this.currentItem = item
            } else {
                this.$store.commit('survey/SET_ACTIVE_VALUE', this.currentItem)
                this.$store.commit('survey/SET_ACTIVE_VALUE', item)
                this.currentItem = item
            }

            // "own" values are custom values entered by the user (in opposite to "built-in" values) 
            if (this.currentItem.isOwn) {
                this.disabled = true
                this.tabIndex = 1
            } else {
                this.disabled = false
                this.$nextTick(() => {
                    this.tabIndex = 0
                })
            }

            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('itemID', item.id)
            evt.dataTransfer.setData('isOwn', item.isOwn)
            let tabKontext = this.$refs.tabKontext
            let tabNote = this.$refs.tabNote
            tabKontext.scrollTop = 0
            tabNote.scrollTop = 0
        },
        onDrop(evt, list) {
            const itemID = evt.dataTransfer.getData('itemID')

            // dropZone is the 'Zielscheibe'
            const dropZone = document.getElementById("drop-zone-profile")
            this.widthDropZone = dropZone.offsetWidth
            this.heightDropZone = dropZone.offsetHeight

            // Calculate relative position within 'Zielscheibe' (in pixel)
            // Note: evt.offsetX/evt.offsetY is offset of mouse pointer relative from left/top corner of target object (in px)
            // Note: mouseX/mouseY is offset of mouse pointer relative from left/top corner of dragged object (in px)
            const x = evt.offsetX - this.mouseX
            const y = evt.offsetY - this.mouseY

            // Calculate relative position within 'Zielscheibe' (in percent)
            this.posLeft = Math.round((x / this.widthDropZone) * 100)
            this.posTop = Math.round((y / this.heightDropZone) * 100)

            let payload = {
                id: itemID,
                title: this.currentItem.title,
                list: list,
                x: x + 'px',
                y: y + 'px',
                percentLeft: this.posLeft,
                percentTop: this.posTop
            }
            this.$store.commit('survey/UPDATE_DROP', payload)
        },
        mouseOver(evt, item) {
        },

        itemClick(evt, item) {
            let rect = evt.target.getBoundingClientRect()
            let x = evt.clientX - rect.left
            let y = evt.clientY - rect.top
            if (JSON.stringify(this.currentItem) === '{}') {
                this.$store.commit('survey/SET_ACTIVE_VALUE', item)
                this.currentItem = item
            }
            else {
                this.$store.commit('survey/SET_ACTIVE_VALUE', this.currentItem)
                this.$store.commit('survey/SET_ACTIVE_VALUE', item)
                this.currentItem = item
            }

            if (this.currentItem.isOwn) {
                this.disabled = true
                this.tabIndex = 1
            }
            else {
                this.disabled = false
                this.$nextTick(() => {
                    this.tabIndex = 0
                })
            }
            let tabKontext = this.$refs.tabKontext
            let tabNote = this.$refs.tabNote
            tabKontext.scrollTop = 0
            tabNote.scrollTop = 0
        },
        changeSize(e) {
            let dropZone = document.getElementById("drop-zone-overview")
            let dropZoneProfile = document.getElementById("drop-zone-profile")

            if (this.isOverview) {
                this.$nextTick(() => {
                    if (dropZone !== null) {
                        const width = dropZone.offsetWidth
                        const height = dropZone.offsetHeight
                        this.widthDropZoneOverview = width
                        this.heightDropZoneOverview = height
                    }
                })
            } else {
                this.$nextTick(() => {
                    const width = dropZoneProfile.offsetWidth
                    const height = dropZoneProfile.offsetHeight
                    this.widthDropZone = width
                    this.heightDropZone = height
                }
                )
            }
        },
        focusOut() {
            this.addValue()
        }
    },
    beforeCreate() {

    },
    created() {
        window.addEventListener("resize", this.changeSize)
        this.isShowTutorialStart = this.showTutorialAtStart

        if (this.isShowTutorialStart === true) {
            this.$store.commit('survey/SET_TUTORIAL_VALUE_REFLECTION')
        }

    },
    beforeMount() {
        this.$store.commit('survey/SET_ALL_VALUES_INACTIVE')
    },
    mounted() {
        this.tabIndex = 0
        let dropZone = document.getElementById("drop-zone-overview")
        let dropZoneProfile = document.getElementById("drop-zone-profile")

        if (this.isOverview) {
            this.$nextTick(() => {
                const width = dropZone.offsetWidth
                const height = dropZone.offsetHeight
                this.widthDropZoneOverview = width
                this.heightDropZoneOverview = height

            })
        } else {
            this.$nextTick(() => {
                const width = dropZoneProfile.offsetWidth
                const height = dropZoneProfile.offsetHeight
                this.widthDropZone = width
                this.heightDropZone = height
            }
            )
        }
        if (this.getAllValuesListTwo.length == 0) {
            this.currentItem = this.getAutonomieValue
            this.$store.commit('survey/SET_ACTIVE_VALUE', this.currentItem)
        }

    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeUnmount() {
    },

    data() {
        return {
            currentItem: {},
            images: {
                dart: require("@/assets/images/elsisat/Zielscheibe.svg"),
            },
            offsetX: 0,
            offsetY: 0,
            x: 0,
            y: 0,
            mouseX: 0,
            mouseY: 0,
            ownValue: '',
            note: '',
            projectOverviewText: "Finden Sie alleine oder im Team heraus, welche Werte Ihre Forschung bestimmen und Ihrem Forschungsprojekt eine Form geben sollen.",
            isShowTutorialStart: false,
            widthDropZone: 0,
            heightDropZone: 0,
            posTop: 0,
            posLeft: 0,
            showOwnValueInput: false,
            widthDropZoneOverview: 0,
            heightDropZoneOverview: 0,
            dropZoneOverview: {},
            disabled: false,
            tabIndex: 0,
        }
    },
}

</script>

<style lang="scss" scoped>
.editPen {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    margin: 10px 10px 0 0;
    border-radius: 6px;
    background: #ECF0F3;
}

.ownValue {
    text-align: center;
    background-color: lightgrey;

    &:focus {
        background: white;
    }
}

.tabs {
    border: none;

    /deep/ .active-tab {
        font-weight: bold;
        border-bottom: 3px solid #4BB3D4;
    }

    .nav-link .tab-title {
        color: black;
    }
}

/deep/ .tab-title-class {
    color: $elsi-color-schrift-normal;
}

.nav-link {
    background-color: red;
}

.drop-zone-delete {
    width: 29%;
    height: 4rem;
    margin-top: 20px;
    align-self: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 3px;
    border: 2px dashed $elsi-color-dunkelgrau;
    background-color: $elsi-color-mittelgrau;
    color: $elsi-color-icon-grau;
}

.drop-zone-overlay {
    aspect-ratio: 1;
    margin: 20px 0 20px 0;

    .drop-zone {
        width: 100%;
        border-radius: 50%;
        background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
        background-size: cover;
        height: 100%;
        padding: 0;
        padding-bottom: calc(100% * 3 / 4);
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
    }
}


.drop-zone-overview {
    aspect-ratio: 1;
    width: 100%;
    border-radius: 50%;
    background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
    background-size: cover;
    height: 100%;
    padding: 0;
    padding-bottom: calc(100% * 3 / 4);
    background-repeat: no-repeat;
    background-position: center center;
}

.item {
    position: absolute;
}

.get-zone {
    margin-top: 32px;
    margin-bottom: 10px;
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.addButton {
    background: lightgrey;
    color: black;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: 100%;
    border: none;

    &:hover {
        background: white;
    }
}



.drag-el {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
        text-align: center;
    }
}

.drag-el-overview {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
}

.isActive {
    background: darken($elsi-color-blue, 8);
    color: white;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: fit-content;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */
}

.isInactive {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: fit-content;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
    }
}

section#value-section {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    // calc: 1rem margin from collapsible
    padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    h4 {
        color: $elsi-color-schrift-normal;
    }

    @media only screen and (max-width: 1920px) {
        .value-section {
            display: grid;
            grid-template-columns: 12% 41% 12% 35%;
            grid-column-gap: 1.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 1921px) {
        .value-section {
            display: grid;
            grid-template-columns: 12% 41% 12% 35%;
            grid-column-gap: 1.5rem;
            width: 100%;
        }
    }
}

#context-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px 24px;
    gap: 40px;
    overflow: hidden;
    line-height: 25px;

    /deep/ .active-tab {
        font-weight: bold;
        border-bottom: 3px solid #4BB3D4;
    }

    .tab-text {
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: scroll;
        height: 600px;
        margin-top: 32px;

        .headline {
            color: $elsi-color-schrift-normal;
            font-weight: 700;
        }

        .sub-title {
            font-weight: 700;
            color: $elsi-color-schrift-graublau;
        }
    }
}
</style>
