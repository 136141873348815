<template>
    <div>
        <!-- Checkboxes on "Project Reflection" page -->
        <div v-if="type === 'projectReflection'">
            <b-form-checkbox :id="`checkbox-${this.step_id}-${this.question.id}`" v-model="isCheckedProjectReflection"
                name="checkbox" value="true" unchecked-value="false" type="checkbox" class="clickable"
                style="margin-left: 48px;text-indent:-30px">
                <span style="margin-left:16px" v-html="question.text"></span>
                <b-icon class="mx-3" v-show="isFavorit" icon="star-fill" :style="{ color: '#F2AF4A' }"></b-icon>
            </b-form-checkbox>
        </div>

        <!-- Checkboxes in "ELSI profile" questionaire -->
        <div v-if="type === 'question'">
            <b-card class="option-card">
                <b-card-body class="checkbox-card" :class="clickable" >
                    <b-form-checkbox :id=" `checkbox-${option.optionId}` " v-model=" isCheckedQuestion " name="checkbox"
                        value="true" unchecked-value="false" type="checkbox" class="clickable">
                        <span style="margin-left: 10px;margin-right:10px;">{{ option.name }}</span>
                    </b-form-checkbox>
                    <div v-if=" option.popUp && option.popUp > 0 " style="display: flex">
                        <feather-icon icon="HelpCircleIcon" size="16" class="clickable" @mouseover=" overPopUp "
                            @click="circleClick" @mouseleave="leavePopUp" style="color:#A8AEB2" />
                        <b-modal size="lg" v-model="showModal" :hide-footer= true >
                            <template #modal-header>
                                {{ popUpTitle }}
                            </template>
                            <template #default>
                                {{ popUpText }}
                            </template>
                        </b-modal>
                    </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>

import Modal from "@/components/Modal.vue"
import FeatherIcon from './FeatherIcon.vue'

export default {
    props: {
        type: String,
        question: Object,
        option: Object,
        step_id: String,
        question_id: Number,
        isFavorit: Boolean,
    },
    components: {
        Modal,
        FeatherIcon
    },
    computed: {
        isCheckedProjectReflection: {
            get() {
                let payload = { step_id: this.step_id, question_id: this.question.id }
                return this.$store.getters['survey/getProjectReflection_QuestionCheck'](payload)
            },
            set(newValue) {
                let payload = { step_id: this.step_id, question_id: this.question.id, checked: newValue === "true" }
                this.$store.commit('survey/SET_CHECKBOX', payload)
            }
        },

        isCheckedQuestion: {
            get() {
                let payload = { question_id: this.question_id, option_id: this.option.optionId, optionName: this.option.name }
                return this.$store.getters['survey/getQuestionCheck'](payload)
            },
            set(newValue) {
                let payload = { question_id: this.question_id, question_inquiry: this.question.inquiry, option_id: this.option.optionId, optionName: this.option.name, checked: newValue === "true" }
                this.$store.dispatch('survey/setCheckboxQuestion', payload)
                let params = { questionId: this.question_id, question_inquiry: this.question.inquiry, optionId: this.option.optionId, name: this.option.name, selected: newValue === "true", points: this.option.points }
                this.$store.commit('survey/UPDATE_ANSWER', params)
            }
        }

    },
    methods: {
        changeMethod() {
            let payload = { question_id: this.question_id, question_inquiry: this.question.inquiry, option_id: this.option.optionId, optionName: this.option.name, checked: this.isChecked }
            this.$store.dispatch('survey/setCheckboxQuestion', payload)
        },
        overPopUp(element) {
            this.isOvercircle = true
            let term = this.$store.getters['survey/getTermById'](this.option.popUp)
            this.popUpTitle = term.term
            this.popUpText = term.definition
        },
        leavePopUp(element) {
            this.isOvercircle = false
        },
        circleClick() {
            this.showModal = !this.showModal;
        }
    },
    mounted() {
    },
    data() {
        return {
            isChecked: false,
            popUpText: '',
            popUpTitle: '',
            isOvercircle: false,
            showModal: false,
        }
    }

};
</script>
<style lang="scss" scoped>
.popUp {
    display: flex;
    position: absolute;
    top: 35%;
    right: 5%;
}

label {
    display: inline !important; // 1
}

.checkbox-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/deep/ input[type="checkbox"] {
    outline: none;
    border: none;
    background-color: #F8F9FB;
}</style>