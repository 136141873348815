<template>
    <div class="wrapper">
        <div class="collbtn clickable" :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="`collapse-${id}`" @click="visible = !visible">
            <b> {{ label }} </b>
            <b-icon icon="chevron-down"></b-icon>
        </div>

        <b-collapse :id="`collapse-${id}`" v-model="visible">
            <b-card class="detail-card" footer-tag="footer" footer-border-variant="none">
                <div>

                    <div v-if="short">
                        <span style="line-height: 25px"> {{ short }}</span>
                    </div>

                    <div v-if="values">
                        <b-list-group class="list-group">
                            <b-list-group-item v-for="value, index in values" :key=index class="item-group">
                                <div class="item" v-b-tooltip.hover :title=value.tooltip>
                                    {{ value.text }}
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <div style="padding-top: 16px;">
                            <p style="color: #7B8B99">Fahren Sie mit der Maus über die einzelnen Werte, um zu sehen, wie sie
                                mit der Kategorie zusammenhängen.</p>
                        </div>
                    </div>

                    <div v-if="laws">
                        <b-list-group class="list-group">
                            <b-list-group-item v-for="value, index in laws" :key=index class="item-group">
                                <div class="item" v-b-tooltip.hover :title=value.tooltip>
                                    {{ value.text }}
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <div style="padding-top: 16px;">
                            <p style="color: #7B8B99">Fahren Sie mit der Maus über die einzelnen Felder, um zu sehen, welche
                                Gesetze und Verordnungen für die Kategorie besonders relevant sind.</p>
                        </div>
                    </div>

                </div>
            </b-card>
        </b-collapse>

    </div>
</template>

<script>
import Checkbox from "./Checkbox.vue"

export default {
    name: "collapse-reflection",
    components: {
        Checkbox
    },
    props: {
        id: String,
        label: String,
        short: String,
        values: Array,
        laws: Array,
    },
    computed: {

    },
    methods: {
        projectReflection() {
            return this.$store.getters['survey/getProjectReflection']
        },
    },
    mounted() {
        if (this.id == 1) {
            this.visible = true
        }
    },
    data() {
        return {
            visible: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 1rem;
}

.detail-card {
    height: auto;
    border: none;
    background-color: #F3F6F8;
    padding-left: 16px;
    padding-right: 16px;
}

.list-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 1rem;

    .item-group {
        background-color: #F3F6F8;
        padding: 0;

        .item {
            display: grid;
            place-content: center;
            hyphens: auto;
            background-color: #FFFFFF;
            padding: 5px 12px 5px 12px;
            gap: 8px;
            height: 100%;
            border-radius: 3px;
            text-align: center;
            justify-content: center;

            &:hover {
                background-color: lightblue;
            }
        }
    }
}


.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #F3F6F8;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }
}

.legal-item {
    display: grid;
    grid-template-columns: 20px 1fr;
    hyphens: auto;
}
</style>
