<template>
    <div>
        <!-- Project Reflection page -->
        <div v-if="!isOverview">
            <div class="row grow w-100">
                <div class="col-1">
                    <NavSidebar></NavSidebar>
                </div>
                <div class="main col-11 h-100 py-3 padding-subpages">
                    <NavHeader tutorialName="projectReflection" buttonLabel="Zur Projektübersicht"
                        :icons="['question-circle', 'download']" :link="true" linkTo="/uebersicht"></NavHeader>
                    <HeaderCard title="Projektreflexion" :icons="[]" info='true' info_color='red'>
                        <template #title>
                            Projektreflexion
                        </template>
                        <template #description>
                            <p>Ziel der Projektreflexion ist es, die Erkenntnisse zu ELSI im Team zu reflektieren und
                                <strong>konkrete Schritte</strong> für das eigene Projekt abzuleiten. Insbesondere in der
                                Antragsphase soll die Checkliste dabei helfen, Ethik und Rechtsfragen rechtzeitig zu
                                bedenken sowie den Blick des gesamten Teams zu fokussieren.
                            </p>
                        </template>
                        <template #info>
                            Versuchen Sie, die folgenden <strong>Fragen und Aufgaben</strong> durchzugehen, um Ihr
                            bestehendes Projekt oder Ihren Projektantrag zu überprüfen. Für jede favorisierte ELSI-Kategorie
                            wird eine zusätzliche Frage eingeblendet.
                        </template>
                    </HeaderCard>
                    <div style="margin: 40px 0px 40px 0px">
                        <h5>Favorisierte ELSI-Kategorien für zusätzliche Reflexionsfragen</h5>
                        <div class="favorit-container">
                            <div v-for="cat, index in getAllClassification" :key="index" class="clickable">
                                <FavItem :category="cat"></FavItem>
                            </div>
                        </div>
                    </div>

                    <section id="content">

                        <div>
                            <h5>Reflexion und Transfer in {{ projectReflection.length }} Schritten</h5>
                            <div style="margin-top: 2rem" v-for="step in projectReflection" :key=step.step_id>
                                <CollapseReflection :step="step"></CollapseReflection>
                            </div>
                        </div>

                        <div class="mb-5">
                            <h5>Zusätzliche Informationen</h5>
                            <div v-show="showValueReflection">
                                <b-card class="overflow-auto" body-class="text-center"
                                    style="border: none; width: 100%; height: fit-content; margin-top: 2rem;"
                                    footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                    footer-bg-variant="white">
                                    <ValueReflection :isOverview=true></ValueReflection>
                                </b-card>
                            </div>
                            <b-card class="overflow-auto" body-class="text-center"
                                style="border: none; width: 100%; height: 30rem; margin-top: 2rem;"
                                footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                footer-bg-variant="white">
                                <b-card-title>Eigene Notizen</b-card-title>

                                <b-form-textarea v-model="getProjectNote" size="sm"
                                    placeholder="z.B. Themen die noch geklärt werden müssen."
                                    style="height: 90%; width: 100%" />
                            </b-card>
                            <div v-show="getShowProfileInOverview">
                                <b-card class="overflow-auto" body-class="text-center"
                                    style="border: none; width: 100%; height: fit-content; margin-top: 2rem;"
                                    footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                    footer-bg-variant="white">
                                    <Profile :isOverview=true></Profile>
                                </b-card>
                            </div>
                            <div style="display: flex; justify-content: flex-end; margin-bottom: 20px; margin-top: 20px">
                                <Button btnStyle="btn-primary" label="In Projektübersicht übernehmen" :chevron="true"
                                    :chevronLeft="false" :link=true linkTo="/uebersicht"></Button>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>

        <!-- Tile "Project Reflection" in Project Overview page -->
        <div v-if="showProjectReflection && isOverview">
            <div class="editPen">
                <feather-icon class="clickable" font-scale="1.5" icon="Edit3Icon" @click="clickEdit"></feather-icon>
            </div>
            <div v-for="step in projectReflection" :key=step.step_id>
                <div v-if="isProjectReflectionStepFinished(step.step_id)" class="steps-overview">
                    <b-card class="steps">
                        <div class="step" style="display: flex; flex-direction: row; align-items: center;">
                            <div>
                                <b-form-checkbox :id="`checkbox-${step.step_id}`" name="checkbox1" checked="true"
                                    value="true" unchecked-value="false" disabled></b-form-checkbox>
                            </div>
                            <div class="number">
                                {{ step.step_id }}
                            </div>
                            <div>
                                {{ step.title }}
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>

        <b-modal v-model="isShowTutorialStart" title='Hinweise' hide-footer>
            <Tutorial tutorialName="projectReflection" />
        </b-modal>
    </div>
</template>

<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue";
import NavHeader from "../../../components/Nav_Header.vue";
import HeaderCard from "../../../components/Header_Card.vue";
import OverviewCollapse from "../../../components/Overview_Collapse.vue";
import CollapseReflection from "../../../components/Collapse_Reflection.vue";
import MyModal from "../../../components/Modal.vue";
import FavItem from "./FavItem.vue";
import ValueReflection from "../valueReflection/ValueReflection.vue";
import Profile from "../profile/Profile.vue";
import FeatherIcon from '../../../components/FeatherIcon.vue';
import Button from "../../../components/Button.vue";
import Tutorial from '../../Tutorial.vue';

export default {
    name: 'projectreflection',
    props: {
        isOverview: Boolean,
    },
    components: {
        NavSidebar,
        NavHeader,
        HeaderCard,
        OverviewCollapse,
        CollapseReflection,
        MyModal,
        FavItem,
        ValueReflection,
        Profile,
        FeatherIcon,
        Button,
        Tutorial
    },
    computed: {
        getAllClassification() {
            return this.$store.getters['survey/getAllCategories']
        },
        showTutorialAtStart() {
            return this.$store.getters['survey/showTutorialProjectReflection']
        },
        getProjectNote: {
            get() {
                return this.projectReflectionNote

            },
            set(value) {
                let payload = {
                    note: value,
                }
                this.$store.commit('survey/UPDATE_PROJECT_REFLECTION_NOTE', payload)
                this.projectReflectionNote = value
            }
        },
        projectReflection() {
            return this.$store.getters['survey/getProjectReflection']
        },
        showProjectReflection() {
            return this.$store.getters['survey/getShowProjectReflection']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
        showValueReflection() {
            return this.$store.getters['survey/getShowValueReflection']
        },
    },
    methods: {
        clickEdit() {
            this.$router.push('/reflexion')
        },
        isProjectReflectionStepFinished(stepId) {
            return this.$store.getters['survey/isProjectReflectionStepFinished'](stepId)
        },
        showTutorial() {
            this.isShowTutorial = !this.isShowTutorial
        },
    },
    beforeMount() {
        this.projectReflectionNote = this.$store.getters['survey/getProjectReflectionNote']
    },
    created() {
        this.isShowTutorialStart = this.showTutorialAtStart
        if (this.isShowTutorialStart === true) {
            this.$store.commit('survey/SET_TUTORIAL_PROJECT_REFLECTION')
        }
    },
    data() {
        return {
            isChecked: false,
            isShowTutorial: false,
            showModalFavorit: false,
            selected: [],
            projectReflectionNote: '',
            isShowTutorialStart: true,
        }
    }
}

</script>

<style lang="scss" scoped>
section#content {
    display: flex;
    flex-direction: column;
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: auto 25%;
    height: 200px;
    margin: 0px;
}

.favorit-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;

    .favorit-item {
        background-color: #FFFFFF;
        padding: 14px 20px;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
}

section#tool-sections {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .tool-sections {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1.5rem;
    }
}

section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 75% 10% 10%;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.steps-overview {
    display: flex;
}

.steps {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px 0 16px 0;
    width: 100%;
    border: none;
}

.step div {
    padding: 10px;
    text-align: left;
    color: $elsi-color-schrift-normal;
}

.step .number {
    color: $elsi-color-schrift-hellgrau;
    font-weight: 700;
}
</style>
