<template>
    <div id="options-panel">

        <div class="collbtn" :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="`collapse-${id}`" @click="clickVisible">
            {{ label }}
            <b-icon icon="chevron-down"></b-icon>
        </div>

        <b-collapse :id="`collapse-${id}`" ref="collapseRef" v-model="visible" height="200px">
            <div v-if="label === 'ELSI-Wertereflexion'">
                <div class="overviewItem">
                    <b-card class="overflow-auto b-card-overview" footer-class="align-self-center" footer-tag="footer"
                        footer-border-variant="none" footer-bg-variant="white">
                        <b-card-body class="b-card-body-overview">
                            <img src="~@/assets/images/overview/valueReflection.svg" width="100%" alt="">
                            <p class="mt-4">{{ text }}</p>
                        </b-card-body>
                        <b-card-footer class="myfooter">
                            <Button :label="labelButton" class="full-width"
                                :btnStyle="!showValueReflection ? 'btn-primary' : 'btn-secondary'" :icon="icon" :link="link"
                                :linkTo="linkTo"></Button>
                        </b-card-footer>
                    </b-card>
                </div>
            </div>
            <div v-if="label === 'ELSI-Profil'">
                <div class="overviewItem">
                    <b-card class="overflow-auto b-card-overview" footer-class="align-self-center" footer-tag="footer"
                        footer-border-variant="none" footer-bg-variant="white">
                        <b-card-body class="b-card-body-overview">
                            <img src="~@/assets/images/overview/profile.svg" width="100%" alt="">
                            <p class="mt-4">{{ text }}</p>
                        </b-card-body>
                        <b-card-footer class="myfooter">
                            <Button :label="labelButton" class="full-width"
                                :btnStyle="!getShowProfileInOverview && showValueReflection ? 'btn-primary' : 'btn-secondary'"
                                :icon="icon" :link="link" :linkTo="linkTo"></Button>
                        </b-card-footer>
                    </b-card>
                </div>

            </div>
            <div v-if="label === 'ELSI-Karten'">
                <div class="overviewItem">
                    <b-card class="overflow-auto b-card-overview" footer-class="align-self-center" footer-tag="footer"
                        footer-border-variant="none" footer-bg-variant="white">
                        <b-card-body class="b-card-body-overview">
                            <img src="~@/assets/images/overview/cards.svg" width="100%" alt="">
                            <p class="mt-4">{{ text }}</p>
                        </b-card-body>
                        <b-card-footer class="myfooter">
                            <Button :label="labelButton" class="full-width"
                                :btnStyle="getShowProfileInOverview && showValueReflection && !showCardsInOverview ? 'btn-primary' : 'btn-secondary'"
                                :icon="icon" :link="link" :linkTo="linkTo"></Button>
                        </b-card-footer>
                    </b-card>
                </div>

            </div>
            <div v-if="label === 'Projektreflexion'">
                <div class="overviewItem">
                    <b-card class="overflow-auto b-card-overview" footer-class="align-self-center" footer-tag="footer"
                        footer-border-variant="none" footer-bg-variant="white">
                        <b-card-body class="b-card-body-overview">
                            <img src="~@/assets/images/overview/reflection.svg" width="100%" alt="">
                            <p class="mt-4">{{ text }}</p>
                        </b-card-body>
                        <b-card-footer class="myfooter">
                            <Button :label="labelButton" class="full-width"
                                :btnStyle="getShowProfileInOverview && showValueReflection && showCardsInOverview && !showProjectReflection ? 'btn-primary' : 'btn-secondary'"
                                :icon="icon" :link="link" :linkTo="linkTo"></Button>
                        </b-card-footer>
                    </b-card>
                </div>

            </div>
        </b-collapse>
    </div>
</template>

<script>

import Button from "./Button.vue";
import ValueReflection from "../views/apps/valueReflection/ValueReflection.vue"
import Profile from "../views/apps/profile/Profile.vue"
import ElsiCards from "../views/apps/elsi_cards/ElsiCards.vue"
import ProjectReflection from "../views/apps/reflection/ProjectReflection.vue"

export default {
    name: "overview-collapse",
    components: {
        Button,
        ValueReflection,
        Profile,
        ElsiCards,
        ProjectReflection,
    },
    props: {
        id: String,
        label: String,
        text: String,
        labelButton: String,
        link: Boolean,
        linkTo: String,
        icon: String,
    },
    computed: {
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
        showValueReflection() {
            return this.$store.getters['survey/getShowValueReflection']
        },
        showProjectReflection() {
            return this.$store.getters['survey/getShowProjectReflection']
        },
        showCardsInOverview() {
            return this.$store.getters['survey/showCardsInOverview']
        },
        getStarredCategories() {
            return this.$store.getters['survey/getStarredCategories']
        },

    },
    methods: {
        checkVisible() {
            let check
            switch (this.label) {
                case 'ELSI-Wertereflexion':
                    if (this.showValueReflection) {
                        this.visible = false
                        check = false
                    }
                    else {
                        this.visible = true
                        check = true
                    }
                    // code block
                    break;
                case 'ELSI-Profil':
                    if (this.getShowProfileInOverview) {
                        this.visible = false
                        check = false
                    }
                    else {
                        this.visible = true
                        check = true
                    }
                    // code block
                    break;
                case 'ELSI-Karten':
                    if (this.getStarredCategories.length > 0) {
                        this.visible = false
                        check = false
                    }
                    else {
                        this.visible = true
                        check = true
                    }
                    break;
                case 'Projektreflexion':
                    if (this.showProjectReflection) {
                        this.visible = false
                        check = false
                    }
                    else {
                        this.visible = true
                        check = true
                    }
                    break;
                default:
                // code block
            }
            return check


        },
        clickVisible() {
            this.visible = !this.visible
        },
    },
    mounted() {
        switch (this.label) {
            case 'ELSI-Wertereflexion':
                if (this.showValueReflection) {
                    this.visible = false
                } else {
                    this.visible = true
                }
                break;
            case 'ELSI-Profil':
                if (this.getShowProfileInOverview) {
                    this.visible = false
                } else {
                    this.visible = true
                }
                break;
            case 'ELSI-Karten':
                if (this.getStarredCategories.length > 0) {
                    this.visible = false
                } else {
                    this.visible = true
                }
                break;
            case 'Projektreflexion':
                if (this.showProjectReflection) {
                    this.visible = false
                } else {
                    this.visible = true
                }
                break;
            default:
            // nothing done here
        }

    },
    unmounted() {
    },
    created() {
    },
    updated() {
    },
    data() {
        return {
            visible: true
        }
    }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1920px) {
    .overviewItem {
        height: 35em;
        max-height: auto;
    }
}

@media only screen and (min-width: 1921px) {
    .overviewItem {
        height: 40em;
        max-height: auto;
    }
}

#options-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    justify-content: center;
}

.myfooter {
    border: none;
    display: flex;
    justify-content: center;
    justify-items: center;
    background: white;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
}

#footer {
    align-self: center;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 0 0;
    width: 100%;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }
}

.collapsed {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.b-card-overview {
    width: 100%;
    height: 100%;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.b-card-body-overview {
    margin: -14px 20px 20px 20px;
    padding: 20px 0 20px 0;
    border-top: 2px solid #E5ECF2;
}
</style>
