<template>
    <div class="wrapper">

        <!-- Header of this step with title -->
        <div class="collbtn clickable" :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="`collapse-${step.step_id}`" @click="visible = !visible">
            <b-form-checkbox v-model="stepStatus" value="true" unchecked-value="false" disabled />
            <div class="step-id">{{ step.step_id }}</div>
            <div class="step-title">{{ step.title }}</div>
            <b-icon icon="chevron-down"></b-icon>
        </div>

        <!-- Collapsible part with list of questions contained in this step -->
        <b-collapse :id="`collapse-${step.step_id}`" :visible="visible">
            <div class="card-questions">
                <div class="questions">
                    <div v-for="question in step.reflection_questions" :key="step.step_id + question.id">
                        <Checkbox v-if="question.cat === 'none' || getFavoritesFilter.includes(question.cat)"
                            :isFavorit="getFavoritesFilter.includes(question.cat)" type="projectReflection"
                            :step_id="step.step_id" :question="question" class="mb-5" />
                    </div>
                </div>
            </div>
        </b-collapse>

    </div>
</template>

<script>
import Button from "./Button.vue";
import Checkbox from "./Checkbox.vue"

export default {
    name: "collapse-reflection",
    components: {
        Button,
        Checkbox
    },
    props: {
        step: Object,
    },
    computed: {
        getFavoritesFilter() {
            return this.$store.getters['survey/getProjectReflectionFavorits']
        },
        stepStatus: {
            get() {
                return this.$store.getters['survey/isProjectReflectionStepFinished'](this.step.step_id)
            },
            set(newStatus) {
                // nothing done here yet.
            }
        }
    },
    methods: {
        projectReflection() {
            return this.$store.getters['survey/getProjectReflection']
        },
    },
    mounted() {
        if (this.step.step_id == 1) {
            this.visible = true
        }
    },
    data() {
        return {
            visible: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 16px;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    padding: 24px 32px;
    gap: 24px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.2s;
    }

    .step-id {
        color: $elsi-color-schrift-hellgrau;
        font-weight: bold;
    }

    .step-title {
        font-weight: bold;
        flex: 1;
    }

    &[aria-expanded="true"] {
        border-radius: 6px 6px 0 0;

        .b-icon {
            transform: rotate(180deg);
        }
    }

}

.card-questions {
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 6px 6px;
    border: none;
    padding: 0 32px 0 32px;
}

.questions {
    border-top: 2px solid #E5ECF2;
    padding: 32px 0 16px 0;
}
</style>
