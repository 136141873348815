import { render, staticRenderFns } from "./ElsiCard.vue?vue&type=template&id=531d1ee3&scoped=true"
import script from "./ElsiCard.vue?vue&type=script&lang=js"
export * from "./ElsiCard.vue?vue&type=script&lang=js"
import style0 from "./ElsiCard.vue?vue&type=style&index=0&id=531d1ee3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531d1ee3",
  null
  
)

export default component.exports