<template>
    <div>
        <!-- ELSI profile page -->
        <div v-if="!isOverview">
            <div class="row grow w-100">
                <div class="col-1">
                    <NavSidebar />
                </div>

                <div class="main col-11 h-100 py-3 padding-subpages">
                    <NavHeader tutorialName="profile" buttonLabel="Zur Projektübersicht" chevron="true" chevronLeft="true"
                        :link="true" linkTo="/uebersicht" />
                    <!-- Before answering questions -->
                    <HeaderCard v-if="!getShowProfileInOverview" :icons="[]" info='true' info_color='red'
                        buttonLabel="Fragen beantworten" :link=true linkTo="/survey">
                        <template #title>
                            <h4>ELSI-Profil</h4>
                        </template>
                        <template #description>
                            <p>
                                Das ELSI-Profil bietet einen Überblick über <b>10 ELSI-Kategorien</b> und ihre
                                Relevanz für Ihr eigenes Forschungsvorhaben. Diese wird anhand Ihrer Antworten ermittelt.
                            </p>
                        </template>
                        <template #info>
                            <p>Beantworten Sie <b>alle</b> Fragen, um die Relevanz der ELSI-Kategorien für Ihr
                                Forschungsprojekt zu ermitteln. Denken Sie dabei aus der Perspektive eines
                                Anwendungsszenarios Ihrer zu entwickelnden Technologie.
                            </p>
                        </template>
                    </HeaderCard>

                    <HeaderCard v-if="getShowProfileInOverview" :icons="[]" info='true' info_color='red'
                        buttonLabel="Fragen bearbeiten" :link=true linkTo="/survey">
                        <template #title>
                            <h4>ELSI-Profil</h4>
                        </template>
                        <template #description>
                            <p> Das ELSI-Profil bietet einen Überblick über <b>10 ELSI-Kategorien</b> und ihre
                                Relevanz für Ihr eigenes Forschungsvorhaben. Diese wird anhand Ihrer Antworten ermittelt.
                                Umso voller der Relevanzindikator einer ELSI-Kategorie, desto wichtiger ist es, dass Sie
                                sich mit der Thematik beschäftigen und keine Auswirkungen übersehen.</p>
                        </template>
                        <template #info>
                            <p>Sehen Sie sich an, welche Ihrer Antworten auf <b-icon class="not-rotate wahrscheinlich"
                                    scale="1" icon='circle-fill'></b-icon> wahrscheinliche, <b-icon
                                    class="not-rotate möglich" scale="1" icon='circle-fill'></b-icon> mögliche und <b-icon
                                    class="not-rotate unwahrscheinlich" scale="1" icon='circle-fill'></b-icon>
                                unwahrscheinliche <b>Implikationen</b> hinweisen. Informieren Sie sich zu den für Sie
                                wichtigsten ELSI-Kategorien.
                            </p>
                        </template>
                    </HeaderCard>

                    <div
                        :style="{ background: getShowProfileInOverview ? '#F8F8F8' : null, opacity: getShowProfileInOverview ? '1' : '0.4' }">
                        <section id="content-header">
                            <div class="content-header-bar">
                                <div style="display: flex; justify-content: center;">Sortieren</div>
                                <div style="display: flex; justify-content: flex-end;">Kategorie</div>
                                <div>Relevanzindikator</div>
                                <div>Fragen</div>
                                <div>Favorit</div>
                            </div>
                            <div class="content">
                                <SortSidebar type="profile"></SortSidebar>
                                <section id="categorys">
                                    <div class="categorys">
                                        <div v-for="category in getAllCategories" :key=category.id>
                                            <CollapseProfileCategory :category="category"></CollapseProfileCategory>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </section>
                    </div>

                </div>

            </div>

            <div
                style="display: flex; justify-content: flex-end; margin-right: 40px; margin-bottom: 20px; padding-bottom: 40px;">
                <Button btnStyle="btn-primary" label="In Projektübersicht übernehmen" :chevron="true" :chevronLeft="false"
                    :link=true linkTo="/uebersicht"></Button>
            </div>

        </div>

        <!-- Tile "ELSI profile" in Project Overview page -->
        <div v-if="getShowProfileInOverview && isOverview">
            <div class="editPen">
                <feather-icon class="clickable" font-scale="1" icon="Edit3Icon" @click="clickEdit" />
            </div>
            <section id="overview-categorys">
                <div v-for="category in getAllCategories" :key=category.id>
                    <div class="overview-categorys">
                        <div class="category-name">{{ category.inquiry }}</div>
                        <ProgressBar nogap :segments_total=category.maxAnswers.length
                            :segments_1=getCategoryImplications_1(category) :segments_2=getCategoryImplications_2(category)>
                        </ProgressBar>
                        <div>
                            {{ category.answers.length }} / {{ category.maxAnswers.length }}
                        </div>
                        <div>
                            <b-icon :icon="category.isStarred ? 'star-fill' : 'star'"
                                :style="{ color: '#F2AF4A' }"></b-icon>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <b-modal v-if="(isOverview === false) && hasTutorialBeenShownOnceAfterSuveryCompleted" v-model="isShowTutorialStart"
            title='Hinweise' ok-only>
            <Tutorial tutorialName="profile" />
            <template #modal-footer>
                <p>Jeder Kategorie sind unterschiedlich viele Fragen zugeordnet. Der Relevanzindikator füllt sich relativ zu
                    dieser Anzahl an Fragen. Daher sind z.B. die Segmente bei „persönliche Integrität“ kürzer als diejenigen
                    bei „Umwelt“.</p>
            </template>
        </b-modal>

    </div>
</template>
<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue"
import SortSidebar from "../../../components/Sort_Sidebar.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import CollapseProfileCategory from "../../../components/Collapse_Profile_Category.vue"
import ProgressBar from "../../../components/ProgressBar.vue";
import FeatherIcon from '../../../components/FeatherIcon.vue';
import Button from "../../../components/Button.vue";
import Tutorial from '../../Tutorial.vue'

export default {
    name: 'profile',
    props: {
        isOverview: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NavHeader,
        HeaderCard,
        NavSidebar,
        SortSidebar,
        CollapseProfileCategory,
        ProgressBar,
        FeatherIcon,
        Button,
        Tutorial
    },
    computed: {
        getAllCategories() {
            return this.$store.getters['survey/getAllCategories']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
        showTutorialAtStart() {
            return this.$store.getters['survey/showTutorialProfile']
        },
        hasTutorialBeenShownOnceAfterSuveryCompleted() {
            if ((this.isShowTutorialStart === true) && (this.$store.getters['survey/getCountQuestionUnanswered'] == 0)) {
                // Show tutorial only once, after all questions of survey have been completed.
                this.$store.commit('survey/SET_TUTORIAL_PROFILE')
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        clickEdit() {
            this.$router.push('/profile')
        },
        getCategoryImplicationsLength(category) {
            return this.$store.getters['survey/getCategoryImplicationsLength'](category)
        },
        getCategoryImplications_1(category) {
            return this.$store.getters['survey/getCategoryImplications_1'](category)
        },
        getCategoryImplications_2(category) {
            return this.$store.getters['survey/getCategoryImplications_2'](category)
        }
    },
    created() {
        this.isShowTutorialStart = this.showTutorialAtStart
    },
    data() {
        return {
            description: "Das ELSI-Profil bietet einen Überblick über 10 ELSI_Kategorien und ihre Relevanz für Ihr eigenes Forschungsvorhaben. Diese wird anhand Ihrer Antworten ermittelt. Umso voller der Relevanzindikator einer ELSI-Kategorie, desto wichtiger ist es, dass Sie sich mit der Thematik beschäftigen und keine Implikationen übersehen.",
            info: "Sehen Sie sich an, welche Ihrer Antworten wahrscheinliche, mögliche und unwahrscheinliche Implikationen mit sich bringen. Informieren Sie sich zu den für Sie wichtigsten ELSI-Kategorien.",
            clickedCat: false,
            currentQuestion: {},
            boldText: '10 ELSI_Kategorien',
            maxLength: 0,
            segment1: 0,
            segment2: 0,
            isShowTutorialStart: true,
            projectOverviewText: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
        }
    }
}

</script>

<style lang="scss" scoped>
section#content-header {
    display: grid;
    grid-column-gap: 1.5rem;
    padding: 16px 0 32px 0;

    .content-header-bar {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 5% 14.5% 64% 5% 5%;
        height: 5rem;
        align-content: center;
        align-items: center;
        font-weight: bold;
        grid-column-gap: 1.5rem;
    }

    .content {
        grid-template-columns: minmax(100px, 100px) auto;
        grid-column-gap: 1.5rem;
        display: grid;
    }
}

.editPen {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    border-radius: 6px;
    background: #ECF0F3;
}

.wahrscheinlich {
    color: $elsi-color-blue;
}

.möglich {
    color: $elsi-color-lighterblue;
}

.unwahrscheinlich {
    color: $elsi-color-schrift-hellgrau;
}

section#categorys {
    display: flex;
    flex-direction: column;

    .categorys {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 1.5rem;
        word-wrap: anywhere;
        word-break: break-word;
    }

}

section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 12fr 6fr 4fr 1fr;
        grid-column-gap: 1rem;
        height: 5rem;
        max-height: 10rem;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .category-name {
        text-align: right;
    }

    .category-bar {
        background-color: red;
    }
}</style>