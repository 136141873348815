<template>
    <div>
        <div v-if="!isOverview">
            <div class="row grow w-100">
                <div class="col-1">
                    <NavSidebar></NavSidebar>
                </div>
                <div class="main col-11 h-100 py-3 padding-subpages">
                    <NavHeader v-if="!clickedCat" buttonLabel="Zur Projektübersicht" chevron="true"
                        chevronLeft="true" tutorialName="none" :link="true" linkTo="/uebersicht"></NavHeader>
                    <NavHeader v-if="clickedCat" buttonLabel="Zurück" chevron="true" tutorialName="none" :isElsiCard=true
                        @back-clicked="backClicked" chevronLeft="true" linkTo="/elsi_cards/abc"></NavHeader>
                    <HeaderCard v-if="!clickedCat" info='true' :icons="[]" info_color='red'>
                        <template #title>ELSI-Karten</template>
                        <template #description>
                            <p>In den ELSI-Karten können Sie mehr zu den jeweiligen <b>ELSI-Kategorien</b> erfahren, die für
                                Forschungsprojekte im Bereich Gesundheit und Pflege relevant sein können. Der Balken über
                                der Karte zeigt die aus Ihrem ELSI-Profil abgeleitete Relevanz.</p>
                        </template>
                        <template #info>
                            Klicken Sie auf eine Karte, um sich mit der jeweiligen ELSI-Kategorie zu beschäftigen.
                        </template>
                    </HeaderCard>

                    <section v-if="!clickedCat" id="content-header-survey">
                        <div class="content-header-bar-survey">
                            <div style="display: flex; justify-content: center;">Sortieren</div>
                            <div >Karten</div>
                        </div>

                        <div v-if="!clickedCat" class="content-survey">
                            <SortSidebar type="cards" />
                            <section id="cards">
                                <div class="cards">
                                    <div v-for="category in getAllClassification" :key=category.id>
                                        <ElsiCard :isOverview=true :id="category.id" :title="category.inquiry"
                                            :description="category.short" :isStarred="category.isStarred"
                                            :category="category" @card-clicked="cardDetail(category)" />
                                    </div>
                                </div>
                                <div
                                    style="display: flex; justify-content: flex-end; margin-right: 40px; margin-bottom: 20px; margin-top: 20px">
                                    <Button btnStyle="btn-primary" label="In Projektübersicht übernehmen" :chevron="true"
                                        :chevronLeft="false" :link=true linkTo="/uebersicht"></Button>
                                </div>
                            </section>
                        </div>
                    </section>

                    <div v-if="clickedCat">
                        <ElsiCardDetail :category="this.currentCategory" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isOverview && getStarredCategories.length > 0" class="overviewCards">
            <div class="editPen" style="z-index: 20">
                <feather-icon class="clickable" font-scale="1.5" icon="Edit3Icon" @click="clickEdit" />
            </div>
            <div v-for="category in getStarredCategories" :key=category.id>
                <ElsiCard :isProjectOverview=true :id="category.id" :title="category.inquiry" :description="category.short"
                    :isStarred="category.isStarred" :category="category" />
            </div>
        </div>
    </div>
</template>
<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue"
import SortSidebar from "../../../components/Sort_Sidebar.vue"
import ElsiCardDetail from "./ElsiCardDetail.vue"
import ElsiCard from "./ElsiCard.vue";
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import OverviewCollapse from "../../../components/Overview_Collapse.vue"
import { mapGetters } from "vuex";
import FeatherIcon from '../../../components/FeatherIcon.vue';
import Button from "../../../components/Button.vue";

export default {
    name: 'elsi-cards',
    props: {
        isOverview: Boolean,
        isClickedCategory: Boolean,
    },
    components: {
        ElsiCard,
        NavHeader,
        HeaderCard,
        OverviewCollapse,
        NavSidebar,
        SortSidebar,
        ElsiCardDetail,
        FeatherIcon,
        Button
    },
    computed: {
        ...mapGetters([]),
        baseUrl() {
            //const path = "/apps/classification"
            const path = this.$route.path
            return path.slice(0, path.lastIndexOf("/"))
        },
        getName() {
            //const path = "/apps/classification"
            return this.$route.params.filter

        },
        classificationFilter() {
            return this.$route.params.filter
        },

        getAllClassification() {
            return this.$store.getters['survey/getAllCategories']
        },
        getStarredCategories() {
            return this.$store.getters['survey/getStarredCategories']
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
    },
    methods: {
        clickEdit() {
            this.$router.push('/elsi_cards')
        },

        backClicked() {
            this.clickedCat = false;
            this.currentCategory = '';
            this.$router.back()
        },

        cardDetail(category) {
            this.clickedCat = true
            this.currentCategory = category;
            let url = '/elsi_cards/' + this.currentCategory.inquiry
            this.$router.push(url)
        },
        cardsOverview(buttonLabel) {
            this.clickedCat = false;
            this.currentCategory = '';
        },

    },
    beforeMount() {
        if (this.$route.params.filter === 'abc') {
            this.clickedCat = false
            this.currentCategory = ''
        }
        else {
            this.clickedCat = true
            this.currentCategory = this.$store.getters['survey/getClassificationByName'](this.$route.params.filter)
        }
    },
    mounted() {
    },
    updated() {
        if (this.$route.params.filter === 'abc')
            this.clickedCat = false
    },

    data() {
        return {
            clickedCat: false,
            currentCategory: {},
            projectOverviewText: "Informieren Sie sich zu relevanten ELSI-Kategorien und lassen Sie sich von den Fallbeispielen und dem Reflexionsprozess inspirieren.",
        }
    }
}

</script>

<style lang="scss" scoped>
section#content-header-cards {
    display: grid;
    grid-column-gap: 1.5rem;
    padding: 16px 0 32px 0;

    .content-header-bar-cards {
        display: grid;
        grid-template-columns: minmax(100px, 100px) auto;
        height: 5rem;
        align-content: center;
        font-weight: bold;
        grid-column-gap: 1.5rem;
    }

}

section#cards {
    display: flex;
    flex-direction: column;
    padding: 0;

    .cards {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
}

.overviewCards {
    height: fit-content
}</style>