<template>
    <div class="favorit-item">{{ this.category.inquiry }}
        <b-icon class="iconStarred mx-3" font-scale="1.5" :icon="this.category.isStarred ? 'star-fill' : 'star'"
            :style="{ color: starred ? '#F2AF4A' : '#F6C780' }" @click="toggleIsStarred"></b-icon>
    </div>
</template>

<script>

import ProgressBar from "../../../components/ProgressBar.vue"
export default {
    name: 'fav-item',
    props: {
        category: Object,
    },
    components: {
        ProgressBar
    },
    computed: {
        starred() {
            return this.category.isStarred
        }
    },
    methods: {
        test() {
        },
        toggleIsStarred() {
            const payload = { categoryId: this.category.id, value: !this.category.isStarred }

            this.$store.dispatch('survey/toggleIsStarredCategory', payload)
        },

    },

    data() {
        return {
            wertesystem: "Finden Sie alleine oder im Team heraus, welche Werte  Ihre Forschung bestimmen und Ihrem Forschungsprojekt eine Form geben sollen.",
            profil: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes  bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
            karten: "Informieren Sie sich zu relevan-ten ELSI-Kategorien und lassen Sie sich von den Fallbeispielen und dem Reflexionsprozess inspirieren.",
            reflexion: "Reflektieren Sie ihr eigenes Forschungprojekt anhand einer indivialisierbaren ELSI-Checkliste und leisten Sie den Transfer von den ELSI-Inhalten zum eigenen Forschungsvorhaben.",
            activeColor: '#F2AF4A',
        }
    }
}

</script>

<style lang="scss" scoped>
.favorit-item {
    border-radius: 6px;
}

.starredIcon {
    position: absolute;
    top: 0px;
    right: 0px;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}
</style>
