<template>
    <div class="row grow w-100">
        <b-card no-body style="border: none">
            <div class="starredIconDetail">
                <b-icon class="iconStarred clickable" font-scale="4" :icon="starred ? 'star-fill' : 'star'"
                    :class="starred ? 'star-dunkel' : 'star-hell'" @click="toggleIsStarred"></b-icon>
            </div>
            <section id="detail-section">
                <div class="detail-section">
                    <div class="short-section">
                        <h1 style="margin-bottom: 50px"> {{ category.inquiry }}</h1>
                        <DetailCollapse id=1 label="Kurzbeschreibung" :short="this.category.short"></DetailCollapse>
                        <DetailCollapse id=2 label="Ethische Werte in medizinethischen Diskursen" :values="this.category.values"></DetailCollapse>
                        <DetailCollapse id=3 label="Gesetze / Rechtliches" :laws="this.category.law"></DetailCollapse>
                    </div>
                    <div>

                        <b-tabs class="tabs" active-nav-item-class="active-tab" justified>
                            <b-tab title="Überblick" active :title-link-class="'tab-title-class'">
                                <b-card-text>
                                    <div class="overview-pic">
                                        <b-list-group class="overview-listgroup">
                                            <h2>Überblick über die ELSI-Kategorie</h2>
                                            <b-list-group-item v-for="(factor, index) in this.category.factors" :key="index"
                                                class="overview-listgroup-item">
                                                <b-icon icon="circle-fill" font-scale="0.5" style="margin-right: 16px">
                                                </b-icon>
                                                <span style="line-height: 25px"> {{ factor }}</span>

                                            </b-list-group-item>

                                        </b-list-group>
                                        <div style="display: flex; justify-content: center;">

                                            <img class="img-fluid" style="margin: 0px 0px 0px 50px; border-radius: 10px"
                                                :src="imagePath(this.category.id)" width="100%" alt="">

                                        </div>

                                    </div>
                                    <div>

                                    </div>
                                    <p class="mt-5"><b>Relevanzindikator</b></p>
                                    <div
                                        v-if="getCategoryImplications_1(this.category) > 0 || getCategoryImplications_2(this.category) > 0">
                                        <ProgressBar :segments_total=this.category.maxAnswers.length
                                            :segments_1=getCategoryImplications_1(this.category)
                                            :segments_2=getCategoryImplications_2(this.category) />
                                    </div>
                                    <div v-else>
                                        <ProgressBar :segments_total=this.category.maxAnswers.length :segments_1=0
                                            :segments_2=0 />
                                    </div>
                                    <p class="mt-5"><b>Zuordnung</b></p>
                                    <div class="categoryTypes">
                                        <div class="type"
                                            :class="this.category.categoryType === 'system' ? 'marked' : 'unmarked'">
                                            <feather-icon font-scale="1.5" icon="SettingsIcon"></feather-icon>Systemisch
                                        </div>
                                        <div class="type"
                                            :class="this.category.categoryType === 'individual' ? 'marked' : 'unmarked'">
                                            <feather-icon font-scale="1.5"
                                                icon="UserIcon"></feather-icon>Individuell
                                        </div>

                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="Beschreibung" :title-link-class="'tab-title-class'">
                                <b-card-text style="padding-top: 32px; line-height: 25px">
                                    <div>
                                        <h5>Beschreibung der ELSI-Kategorie {{ this.category.inquiry }} </h5>
                                        <span v-html="this.category.longDescription[0]"> </span>
                                    </div>

                                    <div v-if="category.techTraits.title !== ''">
                                        <div>
                                            <strong>{{ this.category.techTraits.title }}</strong>
                                        </div>
                                        <b-card v-if="category.techTraits.title !== ''" no-body
                                            style="margin-top: 16px; border: none" class="traits">
                                            <b-list-group style="padding-top: 16px">
                                                <b-list-group-item v-for="(traits, index) in this.category.techTraits.text"
                                                    :key="index" class="traits">
                                                    <b-row>
                                                        <b-col cols="1"
                                                            style="display: flex; justify-content: center; align-self: center; ">
                                                            <b-icon icon="circle-fill" font-scale="0.5"></b-icon></b-col>
                                                        <b-col cols="11"><span v-html="traits"> </span>
                                                        </b-col>

                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-card>
                                    </div>
                                </b-card-text>

                            </b-tab>
                            <b-tab title="Konkretisierung" :title-link-class="'tab-title-class'">
                                <b-card-text style="padding-top: 32px; line-height: 25px">
                                    <b-list-group>
                                        <b-list-group-item v-for="(scenario, index) in this.category.scenarios"
                                            :key="index">
                                            <b-row>
                                                <b-col cols="1"
                                                    style="display: flex; justify-content: center; align-self: top; padding-top: 16px">
                                                    <b-icon icon="circle-fill" font-scale="0.5"></b-icon></b-col>
                                                <b-col cols="11"><span v-html="scenario"> </span></b-col>

                                            </b-row>

                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card-text>

                            </b-tab>
                            <b-tab title="Fallbeispiel 1" :title-link-class="'tab-title-class'">
                                <b-card-text style="padding-top: 32px; line-height: 25px">

                                    <h5><b>{{ this.category.cases[0].title }}</b> </h5>
                                    <p><span v-html="this.category.cases[0].text"></span></p>

                                    <h5 class="mt-5 mb-2"> Konflikte und Dilemmata</h5>
                                    <b-list-group>
                                        <b-list-group-item v-for="(conflict, index) in this.category.cases[0].conflicts"
                                            :key="index" class="traits">
                                            <b-row>
                                                <span v-html="conflict"> </span>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>

                                    <h5 class="mt-5 mb-2">Relevanz für Technikentwicklung</h5>

                                    <b-list-group>
                                        <b-list-group-item v-for="(relevances, index) in this.category.cases[0].relevance"
                                            :key="index" class="traits">
                                            <b-row>
                                                <span v-html="relevances"> </span>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>

                                </b-card-text>

                            </b-tab>
                            <b-tab title="Fallbeispiel 2" :title-link-class="'tab-title-class'">
                                <b-card-text style="padding-top: 32px; line-height: 25px">

                                    <h5><b> {{ this.category.cases[1].title }} </b></h5>
                                    <p><span v-html="this.category.cases[1].text"></span></p>

                                    <h5 class="mt-5 mb-2">Konflikte und Dilemmata</h5>
                                    <b-list-group>
                                        <b-list-group-item v-for="(conflict, index) in this.category.cases[1].conflicts"
                                            :key="index" class="traits">
                                            <b-row>
                                                <span v-html="conflict"> </span>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <h5 class="mt-5 mb-2">Relevanz für Technikentwicklung</h5>

                                    <b-list-group>
                                        <b-list-group-item v-for="(relevances, index) in this.category.cases[1].relevance"
                                            :key="index" class="traits">
                                            <b-row>
                                                <span v-html="relevances"> </span>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>

                                </b-card-text>
                            </b-tab>
                        </b-tabs>

                    </div>
                </div>
            </section>
        </b-card>


    </div>
</template>

<script>
import DetailCollapse from "../../../components/Collapse_ElsiCard_Detail.vue"
import ProgressBar from "../../../components/ProgressBar.vue"
import FeatherIcon from '../../../components/FeatherIcon.vue';

export default {
    name: 'elsi-card-detail',
    props: {
        title: String,
        description: String,
        isStarred: Boolean,
        picture: String,
        category: Object,
    },
    components: {
        DetailCollapse,
        ProgressBar,
        FeatherIcon

    },
    computed: {
        starred() {
            return this.category.isStarred
        },

    },
    methods: {
        imagePath(id) {
            return require(`@/assets/images/elsi-cards/${id}.svg`);
        },
        getCategoryImplications_1(category) {
            return this.$store.getters['survey/getCategoryImplications_1'](category)
        },
        getCategoryImplications_2(category) {
            return this.$store.getters['survey/getCategoryImplications_2'](category)
        },
        toggleIsStarred() {
            const payload = { categoryId: this.category.id, value: !this.category.isStarred }

            this.$store.dispatch('survey/toggleIsStarredCategory', payload)
        },
    },
    mounted() {
        this.$store.commit('survey/SET_SHOW_CARDS_IN_OVERVIEW')
    },

    data() {
        return {

        }
    }
}

</script>

<style lang="scss" scoped>
.zuordnung {
    display: flex;
    align-self: center;
    scale: 2;
}

.categoryTypes {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .marked {
        opacity: 1;
    }

    .unmarked {
        opacity: 0.5;
    }

    .type {
        display: flex;
        background-color: $elsi-color-mittelgrau;
        padding: 5px 12px 5px 12px;
        gap: 16px;
        border-radius: 6px;
        justify-content: center;
        height: 52px;
        width: 190px;
        align-items: center;
    }
}

/deep/ .list-group * {
    border: none;
}

/deep/ .traits {
    background-color: #F3F6F8 !important;
}

section#detail-section {
    background-color: white;
    border-radius: 6px;
    padding: 32px 40px 32px 32px;
    gap: 40px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    /deep/ .overview-pic {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 50% 50%;
        padding-top: 32px;
    }

    .overview-listgroup {

        .overview-listgroup-item {
            display: flex;
            align-items: baseline;
        }

    }

    .detail-section {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 30% auto;
        grid-column-gap: 1.5rem;
    }

    .short-section {
        border-right: 4px dashed #ECF0F3;
        padding-right: 32px;
        gap: 20px;


    }

    .tabs {
        border: none;

        /deep/ .active-tab {
            font-weight: bold;
            border-bottom: 3px solid #4BB3D4;
        }
    }

    /deep/ .tab-title-class {
        color: $elsi-color-schrift-normal;
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .text-section {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2rem;
    }
}

.starredIconDetail {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}
</style>
